import { SEPARATOR } from "@enfusion-ui/core";
const reportUID = (args) => {
    const list = [args.reportId];
    if (args.tableId)
        list.push(args.tableId);
    return list.join(SEPARATOR);
};
export const createGridUID = {
    report: reportUID,
};

export const allocationMetadata = {
    fundName: {
        key: "fundName",
        name: "Fund",
        columnTypeCategory: "String",
    },
    accountName: {
        key: "accountName",
        name: "Account",
        columnTypeCategory: "String",
    },
    custodian: {
        key: "custodian",
        name: "Custodian",
        columnTypeCategory: "String",
    },
    book: {
        key: "book",
        name: "Hierarchy",
        columnTypeCategory: "String",
    },
    allocationPct: {
        key: "allocationPct",
        name: "Order Allocation %",
        columnTypeCategory: "String",
    },
    allocationQuantity: {
        key: "allocationQuantity",
        name: "Order Allocation Quantity",
        columnTypeCategory: "Number",
    },
    orderAllocationNAV: {
        key: "orderAllocationNAV",
        name: "Order Allocation % of NAV",
        columnTypeCategory: "String",
    },
    positionQuantity: {
        key: "positionQuantity",
        name: "Position Quantity",
        columnTypeCategory: "Number",
    },
    positionPercOfNAV: {
        key: "positionPerOfNAV",
        name: "Position % of NAV",
        columnTypeCategory: "String",
    },
    unfilledPercOfNAV: {
        key: "unfilledPercOfNAV",
        name: "Unfilled % of NAV",
        columnTypeCategory: "String",
    },
    resultingPercOfNAV: {
        key: "resultingPercOfNAV",
        name: "Resulting % of NAV",
        columnTypeCategory: "String",
    },
};

import { INTERNAL_PO_IDS, INTERNAL_PO_IDS_LIST, } from "@enfusion-ui/core";
import { createCoreBroadcastChannel } from "@enfusion-ui/hooks";
import * as React from "react";
export const AuthContext = React.createContext(undefined);
export const AUTH_CHANNEL_NAME = "ef-auth-ch";
export const AUTH_CHANNEL = createCoreBroadcastChannel(AUTH_CHANNEL_NAME, {
    webWorkerSupport: false,
});
export function useAuth() {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
}
export function useFlags() {
    const { isUserType, isEnabled, isAdmin, user, isPoId } = useAuth();
    const checkExpressUser = isUserType("Express");
    const [controlledFlags, setControlledFlags] = React.useState({
        oems: false,
        dashboards: false,
        services: true,
        reports: true,
        factset: true,
        compliance: false,
        generalFiles: false,
        portfolios: false,
        operationsFiles: true,
        reconDashboard: false,
        analyticsDashboard: false,
        complianceAssociation: false,
    });
    // This is to allow sales to enable some things in prod to demo even if its not ready for prime time yet.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.changeFlag = (key, value) => {
        setControlledFlags((state) => ({ ...state, [key]: value }));
    };
    const flags = React.useMemo(() => {
        return {
            oems: checkExpressUser || isEnabled("OMS"),
            dashboards: controlledFlags.dashboards || !!process.env.REACT_APP_ENABLE_DASHBOARDS,
            reports: !checkExpressUser && controlledFlags.reports && isEnabled("Reports"),
            services: !checkExpressUser &&
                controlledFlags.services &&
                (!!process.env.REACT_APP_ENABLE_SERVICES_MARKETING ||
                    isEnabled("Services")),
            operationsFiles: !checkExpressUser &&
                controlledFlags.operationsFiles &&
                (!!process.env.REACT_APP_ENABLE_MS_OPERATIONS ||
                    isEnabled("OperationsFiles")),
            generalFiles: !checkExpressUser &&
                (controlledFlags.generalFiles || isEnabled("GeneralFiles")),
            factset: controlledFlags.factset && isEnabled("FactSet"),
            compliance: !checkExpressUser &&
                (controlledFlags.compliance ||
                    !!process.env.REACT_APP_ENABLE_COMPLIANCE) &&
                isEnabled("CompliancePortal"),
            portfolios: (isEnabled("Workbench") ||
                isAdmin() ||
                [
                    ...INTERNAL_PO_IDS_LIST,
                    727319,
                    729614,
                    736978,
                    353699,
                    611489,
                    622855,
                    349670,
                    620377,
                    685504,
                    726126,
                    666564,
                    650731,
                    460675, // "Foresite Capital"
                ].some(isPoId) ||
                controlledFlags.portfolios) &&
                !!process.env.REACT_APP_ENABLE_PORTFOLIOS,
            reconDashboard: isAdmin() ||
                (!!process.env.REACT_APP_ENABLE_RECON_DASHBOARD &&
                    isEnabled("ReconDashboard")),
            analyticsDashboard: isPoId(INTERNAL_PO_IDS.Enfusion) ||
                (process.env.REACT_APP_ENVIRONMENT === "development" &&
                    isPoId(689629)) /* Soros Fund Management LLC */,
            complianceAssociation: !!process.env.REACT_APP_ENABLE_COMPLIANCE_ASSOCIATION,
        };
    }, [user, isEnabled, isPoId, controlledFlags]);
    return flags;
}

import { Model } from "flexlayout-react";
import { noop } from "lodash";
import { createContext } from "react";
export const EMPTY_MODEL_JSON = {
    global: {
        splitterSize: 2,
        tabSetClassNameTabStrip: "layout-tab-set-strip",
        tabEnableRename: false,
    },
    borders: [],
    layout: {
        type: "row",
        children: [
            {
                type: "tabset",
                selected: -1,
                active: true,
                children: [],
            },
        ],
    },
};
export const initialTabsContextState = () => ({
    tabs: [],
    undockedTabs: [],
    layoutModel: Model.fromJson(EMPTY_MODEL_JSON),
    onTabCloseSubscriptions: [],
    onTabWillCloseSubscriptions: {},
    openTab: noop,
    closeTab: noop,
    focusTab: noop,
    renameTab: noop,
    updateTab: noop,
    undockTab: noop,
    dockTab: noop,
    onTabClose: () => noop,
    onTabWillClose: () => noop,
    reUndockTabs: noop,
    closeAllUndocked: noop,
    showUndockConfirmation: false,
});
export const TabsContext = createContext(initialTabsContextState());
export const ThisTabContext = createContext(undefined);

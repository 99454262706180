/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCachedMemo } from "@enfusion-ui/hooks";
import { useLiveQuery } from "dexie-react-hooks";
import * as React from "react";
import { db } from "../../db";
import { useAuth } from "../auth/context";
export const ReportsContext = React.createContext(undefined);
export const TableRowsContext = React.createContext(undefined);
export function useReports() {
    const context = React.useContext(ReportsContext);
    return context || {};
}
export function useStoredReport(reportId, tableId) {
    const { user } = useAuth();
    const res = useLiveQuery(() => db.core.tables.reports.fetchOne({
        reportId: reportId ?? "",
        tableId: tableId ?? reportId ?? "",
        username: user?.username || "",
    }), [reportId, tableId, user?.username || ""]);
    return useCachedMemo(() => res, undefined, [
        res,
    ]);
}
export const ReportsProviderPassthrough = ({ children, ...value }) => (React.createElement(ReportsContext.Provider, { value: value }, children));

import { calcFractionalNotationValues, formatNumber, } from "@enfusion-ui/utils";
import * as React from "react";
import { styled } from "./styledComponents";
const Fraction = styled.span `
  display: inline-block;
  margin-right: 2px;
  font-size: 0.875em;
`;
const Sup = styled.sup `
  margin-left: 1px;
`;
const Sub = styled.sub `
  margin-left: -1px;
`;
export const createFractionalNotationPrice = (value) => {
    const { integer, numberPart, sup, sub } = value;
    const fractionEl = sup ? (React.createElement(Fraction, null,
        React.createElement(Sup, null, sup),
        sub && (React.createElement(React.Fragment, null,
            "\u2044",
            React.createElement(Sub, null, sub))))) : null;
    return (React.createElement("div", null,
        integer,
        "-",
        React.createElement("span", null,
            numberPart,
            fractionEl)));
};
export const PriceCellRenderer = React.memo(({ data, colDef, valueFormatted }) => {
    const dataEntry = data?.[colDef?.colId ?? ""];
    if (typeof dataEntry?.value === "number" &&
        dataEntry.quoteType &&
        dataEntry.quotationFormat) {
        const { value, quoteType, quotationFormat } = dataEntry;
        if (quoteType === "PercentOfPar") {
            if (quotationFormat.startsWith("Price")) {
                const mul = Number(quotationFormat.replace("Price", ""));
                const cal = calcFractionalNotationValues(value, mul);
                return createFractionalNotationPrice(cal);
            }
            if (quotationFormat === "PercentOfPar")
                return React.createElement(React.Fragment, null, formatNumber(value * 100, 2));
        }
    }
    return React.createElement(React.Fragment, null, valueFormatted);
});

import { css, styled } from "@enfusion-ui/web-core";
export const ViewContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  ~ .ag-popup .ag-popup-child {
    z-index: 111;
  }

  .ag-advanced-filter-header-cell .ag-input-field-input {
    border-color: var(--input-border);
  }

  ~ .ag-popup .ag-button,
  .ag-button,
  .ag-advanced-filter-builder-button {
    border: none;
    border-radius: 4px;
    padding: 0.25em 1em;
    cursor: pointer;
    transition: background-color 0.17s;
    &:focus {
      box-shadow: none;
    }
  }

  ~ .ag-popup .ag-advanced-filter-builder-apply-button,
  .ag-advanced-filter-apply-button {
    color: var(--text-normal);
    background-color: var(--primary);

    &:hover {
      background-color: var(--primary-hover);
      opacity: 0.8;
    }

    :disabled,
    :disabled:hover {
      cursor: default;
      background-color: var(--background-color-1);
      color: var(--text-muted);
    }
  }

  ~ .ag-popup .ag-advanced-filter-builder-cancel-button,
  .ag-advanced-filter-builder-button {
    background-color: var(--background-color-1);
    &:hover {
      background-color: var(--background-color-1-hover);
    }
  }

  ~ .ag-popup .ag-advanced-filter-builder-join-pill {
    border-radius: 5px;
    color: var(--preferred-button-color-3);
    background-color: var(--preferred-button-bg-color-3);
    border: 1px solid var(--preferred-button-color-3);
  }

  ~ .ag-popup .ag-advanced-filter-builder-column-pill {
    border-radius: 5px;
    color: var(--preferred-button-color-2);
    background-color: var(--preferred-button-bg-color-2);
    border: 1px solid var(--preferred-button-color-2);
  }

  ~ .ag-popup .ag-advanced-filter-builder-option-pill {
    border-radius: 5px;
    color: var(--preferred-button-color-5);
    background-color: var(--preferred-button-bg-color-5);
    border: 1px solid var(--preferred-button-color-5);
  }

  ~ .ag-popup .ag-advanced-filter-builder-value-pill {
    border-radius: 5px;
    color: var(--preferred-button-color-0);
    background-color: var(--preferred-button-bg-color-0);
    border: 1px solid var(--preferred-button-color-0);
  }
`;
export const CenterContent = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ fillHeight = true }) => (fillHeight ? "height: 100%;" : "")}
  ${({ fillWidth = false }) => (fillWidth ? "width: 100%;" : "")}
`;
export const BoldText = styled.span `
  font-weight: bold;
`;
export const MutedText = styled.span `
  color: var(--text-muted);
`;
export const ACTION_BAR_HEIGHT = 28;
export const ViewContent = styled.div `
  position: relative;
  overflow: hidden;
  ${({ pushUp = false }) => pushUp ? `margin-top: ${0 - ACTION_BAR_HEIGHT}px;` : ""}
  ${({ bordered = true }) => bordered ? "border: 1px solid var(--border);" : ""}
  ${({ fillWidth = false }) => (fillWidth ? "width: 100%;" : "")}
  ${({ fillHeight = true }) => (fillHeight ? "height: 100%;" : "")}
`;
export const TabTopActionBar = styled.div `
  height: ${({ thin }) => (thin ? 0 : ACTION_BAR_HEIGHT)}px;
  min-height: ${({ thin }) => (thin ? 0 : ACTION_BAR_HEIGHT)}px;
  min-width: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--background-color-2);
  ${({ bordered = false }) => bordered
    ? css `
          border: 1px solid var(--border);
          border-bottom-width: 0px;
        `
    : ""}
`;
export const AlignRightRow = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: var(--spacing);
  ${({ flex }) => (flex ? "flex: 1;" : "")}
`;
export const SingleColumnGrid = styled.div `
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
`;
export const SingleColumnGridWithGap = styled(SingleColumnGrid) `
  grid-gap: var(--spacing-xl);
  gap: var(--spacing-xl);
`;
export const WidthHeight100 = styled.div `
  width: 100%;
  height: 100%;
`;
export const SVGBackground = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  background-color: var(--background-color-2);
  background-image: var(--landing-bg);
  background-attachment: fixed;
  background-size: cover;
`;
export const ContentMessageWrapper = styled.div `
  display: flex;
  flex-flow: column;
  height: var(--xl-spacing);

  ${({ isConfigurable = true }) => (isConfigurable ? "cursor: pointer" : "")};
`;

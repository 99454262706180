/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createTestId } from "@enfusion-ui/utils";
import { css, styled } from "@enfusion-ui/web-core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { ErrorMessage } from "@hookform/error-message";
import { kebabCase } from "lodash";
import * as React from "react";
import { IconButton } from "../control/IconButton";
import { InfoPopover } from "../display/Popover";
import { createControlled } from "../forms/ControlledInputs";
const DEFAULT_MIN_WIDTH = "150px";
export const InputLabel = styled.label `
  display: block;
  font-size: 0.75em;
  line-height: 1em;
  font-weight: 500;
  margin: ${({ inline, noMargin }) => noMargin
    ? "0px"
    : inline
        ? "0 var(--spacing-l) 0 var(--spacing)"
        : "var(--spacing-l) 0 var(--spacing) var(--spacing)"};
  ${({ inline = false }) => inline
    ? css `
          align-self: center;
        `
    : ""}
`;
export const InputRequired = styled.span `
  color: var(--primary);
  font-weight: bold;
`;
export const InputErrorMessage = styled.span `
  color: var(--danger);
  font-style: italic;
`;
export const InputContainer = styled.div `
  width: 100%;
  display: flex;
  min-width: ${({ minWidth }) => minWidth ?? DEFAULT_MIN_WIDTH};
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
`;
export const inputStyles = css `
  width: 100%;
  border-radius: 4px;
  color: var(--text-normal);
  background-color: var(--input-background);
  border: 1px solid
    var(${({ hasError = false }) => (hasError ? "--danger" : "--input-border")});
  padding: ${({ numIcons }) => numIcons ? `0.5em ${numIcons * 2}rem 0.5em 0.5em` : "0.5em"};
  outline: none;
  font: inherit;
  font-size: 0.875em;
  cursor: pointer;
  ${({ rows }) => typeof rows !== "undefined"
    ? css `
          min-height: 38px;
        `
    : css `
          height: 38px;
        `} /* to match react-select */

  :focus {
    border-color: var(
      ${({ hasError }) => (hasError ? "--danger" : "--primary")}
    );
  }

  ${({ supportReadOnly = true }) => supportReadOnly
    ? css `
          :read-only {
            color: var(--text-normal);
            background-color: transparent;
            border-color: var(
              ${({ hasError }) => (hasError ? "--danger" : "--input-border")}
            );
          }
        `
    : ""}

  :disabled {
    cursor: not-allowed;
    color: var(--text-muted);
    background-color: var(--background-color-0);
  }

  :hover {
    border-color: var(
      ${({ hasError, disabled }) => hasError ? "--danger" : disabled ? "--input-border" : "--primary-hover"}
    );
  }
`;
export const InputLabelControl = ({ name, errors, label, required, className, style, labelPlaceholder, infoContent, ...labelProps }) => {
    return (React.createElement(InputLabel, { ...labelProps, htmlFor: name, className: className, style: style, noMargin: !label && !labelPlaceholder, title: name ? errors?.[name]?.message : undefined },
        label ? label : labelPlaceholder ? React.createElement(React.Fragment, null, "\u00A0") : "",
        !!label && required && (React.createElement(InputRequired, { style: { marginLeft: !!label ? "var(--spacing-s)" : 0 }, title: "required" }, "*")),
        (!!label || !!labelPlaceholder) &&
            !!errors &&
            !!name &&
            !!errors[name] && (React.createElement(InputErrorMessage, null,
            !!label ? "– " : "",
            React.createElement(ErrorMessage, { errors: errors, name: name }))),
        infoContent && (React.createElement(InfoPopover, { style: { marginLeft: "var(--spacing)" } }, infoContent))));
};
const Input = styled.input `
  ${inputStyles}
`;
const TextArea = styled.textarea `
  ${inputStyles}
  height: 100%;
`;
const InputWithIconsContainer = styled.div `
  position: relative;
  width: 100%;
`;
const IconPanel = styled.div `
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
`;
const IconContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  width: 2rem;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;
export const TextInput = React.forwardRef(function TextInput(props, ref) {
    const { name, type = "text", label, errors, required, textarea, icon, iconContainer, iconInteractive, inline = false, clearable = false, onClearValue, disabled = false, invalid = false, minWidth, value, onChange, defaultValue, nullValue, readOnly, hideLabel = false, placeholder, infoContent, ...rest } = props;
    const [inputValue, setValue] = React.useState(value);
    const internalRef = React.useRef(null);
    React.useEffect(() => {
        setValue(value);
    }, [value]);
    const InputComp = (textarea ? TextArea : Input);
    const clearInput = () => {
        if (onClearValue)
            onClearValue();
    };
    const numIcons = (clearable ? 1 : 0) + (icon ? 1 : 0);
    const hasError = invalid || (errors && name && errors[name]);
    const handleChange = (e) => {
        setValue(e.target.value);
        onChange && onChange(e);
    };
    const handleIconClick = React.useCallback(() => {
        if (!iconInteractive) {
            internalRef.current?.focus();
        }
    }, [iconInteractive, internalRef]);
    const handleRef = React.useCallback((instance) => {
        if (typeof ref === "function") {
            ref(instance);
        }
        else if (ref) {
            ref.current = instance;
        }
        internalRef.current = instance;
    }, [ref]);
    return (React.createElement(InputContainer, { inline: inline, minWidth: minWidth },
        React.createElement(InputLabelControl, { label: label, name: name, inline: inline, required: required, errors: errors, labelPlaceholder: !hideLabel, infoContent: infoContent }),
        React.createElement(InputContainer, { className: "not-moveable", inline: true, style: { flex: 1 }, minWidth: minWidth, title: name ? errors?.[name]?.message : undefined },
            React.createElement(InputWithIconsContainer, null,
                React.createElement(InputComp, { type: type, className: type !== "password" ? "safe-input" : "", autoComplete: "off", ...rest, value: value !== null && typeof value !== "undefined"
                        ? value === nullValue && defaultValue
                            ? defaultValue
                            : value
                        : "", onChange: handleChange, name: name, hasError: hasError, ref: handleRef, numIcons: numIcons, disabled: disabled || readOnly, placeholder: placeholder }),
                React.createElement(IconPanel, null,
                    inputValue && clearable ? (React.createElement(IconContainer, { disabled: disabled },
                        React.createElement(IconButton, { icon: faTimes, onClick: clearInput, disabled: disabled, "data-testid": createTestId("clear-input") }))) : null,
                    icon ? (React.createElement(IconContainer, { disabled: disabled, "data-testid": createTestId("open-menu"), onClick: handleIconClick }, icon)) : null,
                    iconContainer && React.createElement(React.Fragment, null, iconContainer))))));
});
export const ControlledTextInput = createControlled()(TextInput, (props, state) => ({
    ...props,
    invalid: state.invalid,
    "data-testid": `text-input-${kebabCase(props.name)}`,
}));

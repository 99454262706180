/* eslint-disable @typescript-eslint/no-explicit-any */
import { TimeProxy } from "./timeProxy";
const defaultGetter = async (k) => k;
export class AsyncSimpleCache {
    store = new Map();
    threshold = TimeProxy.hours.one.asMilliseconds;
    getValue = defaultGetter;
    constructor(getValue, threshold) {
        this.getValue = getValue;
        if (typeof threshold === "number")
            this.threshold = threshold;
    }
    get = async (key) => {
        if (!this.store.has(key)) {
            const value = await this.getValue(key);
            this.store.set(key, { value, timestamp: Date.now() });
            return value;
        }
        const { timestamp, value } = this.store.get(key);
        if (Date.now() - timestamp <= this.threshold)
            return value;
        this.store.delete(key);
        return this.get(key);
    };
    remove = (key) => {
        this.store.delete(key);
    };
    clear = () => {
        this.store.clear();
    };
}

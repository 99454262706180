import { useMeasure, useScale } from "@enfusion-ui/hooks";
import { AppLogging, styled } from "@enfusion-ui/web-core";
import { faSkullCrossbones } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
// This import path is required for jest
import { Button } from "../control";
const ContentMessageContainer = styled.div `
  color: var(--text-muted);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: scale(${({ scale }) => scale});
`;
const ContentMessageText = styled.div `
  margin: 10px;
`;
const ContentMessageIcon = styled.div `
  /* margin-top: 1em; */
`;
const DetailsWrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 300px;
`;
const Details = styled.div `
  font-size: 0.875em;
  margin-top: var(--spacing-xl);
  user-select: text;
`;
export const ContentMessage = React.memo(function ContentMessage(props) {
    const { icon = faSkullCrossbones, iconSize = "6x", iconColor, message, details, className, contentStyle, detailsStyle, containerStyle, onlyTitleDetails, parentWidth, parentHeight, onIconClick, children, } = props;
    const [ref, { width, height }] = useMeasure();
    const smallScaleRef = React.useRef(null);
    const scale = useScale({ parentHeight, parentWidth, height, width });
    React.useEffect(() => {
        if (smallScaleRef.current === null ||
            smallScaleRef.current > scale ||
            scale === 1) {
            smallScaleRef.current = scale;
        }
    }, [scale]);
    const [show, setShow] = React.useState(false);
    const showContent = typeof smallScaleRef.current === "number" && smallScaleRef.current > 0.5;
    React.useEffect(() => {
        AppLogging.info(`[ErrorView] ${message} (${details})`);
    }, []);
    return (React.createElement(ContentMessageContainer, { ref: ref, scale: scale, title: `${message}${details ? ` - ${details}` : ""}`, style: containerStyle, className: className },
        icon && (React.createElement(ContentMessageIcon, { style: {
                cursor: "help",
                padding: showContent ? 0 : "var(--spacing)",
            }, onClick: onIconClick },
            React.createElement(FontAwesomeIcon, { icon: icon, size: iconSize, color: iconColor }))),
        showContent && (React.createElement(React.Fragment, null,
            React.createElement(ContentMessageText, { style: contentStyle }, message),
            !onlyTitleDetails && details && (React.createElement(DetailsWrapper, null,
                React.createElement(Button, { info: true, onClick: () => setShow((prev) => !prev), className: "not-moveable" }, show ? "Show Less" : "Show More..."),
                show && React.createElement(Details, { style: detailsStyle }, details))),
            children))));
});

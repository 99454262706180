import { css, getCss, styled, useThemeBase } from "@enfusion-ui/web-core";
import { isEqual, kebabCase } from "lodash";
import React from "react";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { createControlled } from "../forms";
import { getSelectStyles, selectTheme } from "./selectStyles";
import { InputLabelControl } from "./TextInput";
export function getOptionValue(options, value, isMulti = false, creatable = false, labelGetter = (entry) => `${entry}`) {
    if (creatable && value && !(options && options.length)) {
        if (Array.isArray(value) && !value?.length)
            return null;
        return { label: labelGetter(value), value: value };
    }
    if (!options || !options.length)
        return null;
    const val = isMulti
        ? Array.isArray(value)
            ? value
            : [value]
        : [value];
    const matchedOptions = options.filter((opt) => val.findIndex((o) => isEqual(o, opt.value)) > -1);
    const unMatchedOptions = val
        .filter((o) => matchedOptions.findIndex((opt) => isEqual(o, opt.value)) < 0)
        .map((entry) => ({ label: labelGetter(entry), value: entry }));
    if (isMulti) {
        if (creatable)
            return [...matchedOptions, ...unMatchedOptions];
        return matchedOptions;
    }
    if (creatable) {
        if (!!matchedOptions[0] && !!unMatchedOptions[0])
            return null;
        return matchedOptions[0] ?? unMatchedOptions[0] ?? null;
    }
    return matchedOptions ?? null;
}
const SelectContainer = styled.div `
  width: 100%;
  ${({ inline }) => inline
    ? css `
          display: flex;
          align-items: center;
          gap: var(--spacing);
        `
    : ""}
`;
const preventArrowUpDownEventPropagation = (event) => {
    if (event.key === "ArrowDown" || event.key === "ArrowUp")
        event.nativeEvent.stopImmediatePropagation();
};
export function Select(props) {
    const { name, label, errors, invalid, required, creatable = false, minWidth, disabled, clearable = true, onChange, inputId, value, labelPlaceholder, inline, dataTestId, infoContent, isMulti, ...rest } = props;
    const theme = useThemeBase();
    const SelectComp = React.useMemo(() => (creatable ? CreatableSelect : ReactSelect), [creatable]);
    const hasError = React.useMemo(() => {
        return (invalid && !!name) || (!!errors && !!name && !!errors[name]);
    }, [invalid, name, errors]);
    const styles = React.useMemo(() => {
        return {
            ...getSelectStyles(minWidth, hasError),
            menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
                ...getCss(theme),
            }),
            container: (provided) => ({
                ...provided,
                flex: 1,
            }),
        };
    }, [minWidth, hasError, theme]);
    return (React.createElement(SelectContainer, { "data-testid": dataTestId || inputId || name, "data-e2e-value": JSON.stringify(value), inline: inline },
        React.createElement(InputLabelControl, { name: name, label: label, errors: errors, required: required, style: { whiteSpace: "nowrap" }, labelPlaceholder: labelPlaceholder, infoContent: infoContent }),
        React.createElement(SelectComp, { styles: styles, className: "react-select__control", classNamePrefix: "prefix-classname", theme: selectTheme, onKeyDown: preventArrowUpDownEventPropagation, menuPortalTarget: document.body, menuPlacement: "auto", isDisabled: disabled, isClearable: clearable, onChange: onChange, formatCreateLabel: (inputValue) => (isMulti ? "Add :" : 'Set "') + inputValue + '"', value: value, inputId: inputId, isMulti: isMulti, ...rest })));
}
export function createControlledSelect() {
    return createControlled()((Select), ({ onChange, ref: _ref, labelGetter, ...props }, state) => ({
        ...props,
        minWidth: 150,
        invalid: state.invalid,
        dataTestId: `select-${kebabCase(props.name)}`,
        isMulti: false,
        value: getOptionValue(props.options, props.value, false, props.creatable, labelGetter) ?? null,
        onChange: (result) => {
            onChange(result ? result.value : null);
        },
    }), {
        defaultWatch: true,
    });
}
export const ControlledSelect = createControlledSelect();
export const ControlledSelectNumber = createControlledSelect();

import { oemsColumnMeta } from "@enfusion-ui/core";
import { PeriodCode } from "@enfusion-ui/types";
import { formatDate, formatDateTime, formatNumber, formatPercentage, formatQuoteCellValue, formatTime, } from "@enfusion-ui/utils";
import { isUndefined } from "lodash";
export function dateCellFormatter({ value }) {
    if (value === null || isUndefined(value))
        return "";
    return formatDateTime(value);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatCellValues(columnTypeCategory, value) {
    if (value === null || isUndefined(value))
        return "";
    switch (columnTypeCategory) {
        case "Price":
            return formatNumber(value, [1, 4, 1]);
        case "MarketPrice":
        case "AveragePrice":
        case "SignedQuantity":
            return formatNumber(value, [0, 2, 2]);
        case "IntegerNumber":
        case "BuySellQuantity":
        case "SignedIntegerQuantity":
            return formatNumber(value, 0);
        case "Number":
        case "SignSensitiveNumber":
            return formatNumber(value);
        case "Percent":
        case "SignSensitivePercent":
        case "PercentFilled":
            return formatPercentage(value, [0, 4, 2]);
        case "Time":
            return formatTime(value) || "";
        case "Date":
            return formatDate(value) || "";
        case "DateTime":
            return formatDateTime(value) || "";
        case "Quotation":
            return formatQuoteCellValue(value);
        case "Boolean":
            return value ? "True" : "False";
        case "IdNumber":
        case "Standard":
        case "Enum":
        case "Compliance":
        case "String":
            return `${value}`;
        default:
            console.log("columnTypeCategory not handled", columnTypeCategory, value);
            return `${value}`;
    }
}
export function orderCellFormatter({ value, colDef }) {
    if (value === null || isUndefined(value))
        return "";
    const key = colDef.colId;
    if (!key || !oemsColumnMeta[key])
        return "";
    if (key === "Tenor")
        return formatTenorCellValue(value);
    const { columnTypeCategory } = oemsColumnMeta[key];
    return formatCellValues(columnTypeCategory, value);
}
export function listCellFormatter({ value, context, colDef, }) {
    if (value === null || isUndefined(value))
        return "";
    const key = colDef.colId;
    if (!key || !context.metadata || !context.metadata[key])
        return "";
    const { columnTypeCategory } = context.metadata[key];
    return formatCellValues(columnTypeCategory, value);
}
export function formatTenorCellValue({ period, multiplier }) {
    if (!period)
        return "";
    switch (period) {
        case "Overnight":
        case "TomorrowNext":
        case "SpotNext":
        case "SpotWeek":
            return PeriodCode[period];
        default:
            return `${multiplier}${PeriodCode[period]}`;
    }
}
export function formatValuesForExportUsingConfiguredFormatters(params) {
    const colDef = params.column.getColDef();
    if (colDef.valueFormatter) {
        const valueFormatterParams = {
            ...params,
            data: params.node?.data,
            node: params.node,
            colDef: params.column.getColDef(),
        };
        const valueFormatter = colDef.valueFormatter;
        return valueFormatter(valueFormatterParams);
    }
    return params.value;
}
export function getExcelStyles(errorTextColor = "#FF0000") {
    return [
        {
            id: "header",
            font: { bold: true },
        },
        {
            id: "leftAlignedCell",
            alignment: { horizontal: "Left" },
        },
        {
            id: "errorText",
            font: { color: errorTextColor },
        },
        {
            id: "rightAlignedCell",
            alignment: { horizontal: "Right" },
        },
    ];
}
